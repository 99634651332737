import { pluralize } from '../utils';
const LABELS_DAYS = ['день', 'дня', 'дней'];
const LABELS_HOURS = ['час', 'часа', 'часов'];
const LABELS_MINUTES = ['минута', 'минуты', 'минут'];
const LABELS_SECONDS = ['секунда', 'секунды', 'секунд'];
class Element {
    constructor(parent, selector, labels) {
        this.element = parent.querySelector(selector);
        this.labelElement = this.element.nextElementSibling;
        this.labels = labels;
        this.label = null;
        this.value = null;
    }
    set(value) {
        if (this.value === value) {
            return;
        }
        this.value = value;
        this.updateElements();
    }
    updateElements() {
        this.element.innerText = this.value.toString();
        const [one, two, five] = this.labels;
        const label = pluralize(this.value, one, two, five);
        if (this.label === label) {
            return;
        }
        this.label = this.labelElement.innerText = label;
    }
}
export default function countdown(element) {
    if (!element) {
        return;
    }
    const days = new Element(element, '.countdown-days', LABELS_DAYS);
    const hours = new Element(element, '.countdown-hours', LABELS_HOURS);
    const minutes = new Element(element, '.countdown-minutes', LABELS_MINUTES);
    const seconds = new Element(element, '.countdown-seconds', LABELS_SECONDS);
    const deadline = Date.parse(element.dataset.deadline);
    tick();
    function tick() {
        const diff = Math.floor((deadline - Date.now()) / 1000);
        if (diff < 0) {
            days.set(0);
            hours.set(0);
            minutes.set(0);
            seconds.set(0);
        }
        else {
            seconds.set(diff % 60);
            minutes.set(Math.floor(diff / 60) % 60);
            hours.set(Math.floor(diff / 60 / 60) % 24);
            days.set(Math.floor(diff / 60 / 60 / 24));
            setTimeout(() => window.requestAnimationFrame ? requestAnimationFrame(tick) : tick(), 500);
        }
    }
}
