var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { StatusClassNames } from "../types/StatusClassNames";
import EventBus from "../events/EventBus";
import { MOSCOW_FIAS, ST_PETERSBURG_FIAS } from "./order/city_helpers";
const locationElements = document.querySelectorAll('.location');
const STORAGE_NAME = 'location_info';
function show(location) {
    for (const el of locationElements) {
        el.querySelector('.btn__label').textContent = location.name;
        el.classList.add(StatusClassNames.ready);
    }
}
function readLocation() {
    const location = window.localStorage.getItem(STORAGE_NAME);
    if (location) {
        let data = JSON.parse(location);
        return Object.assign(Object.assign({}, data), { isFresh: new Date(data.updated).toLocaleDateString() === new Date().toLocaleDateString() });
    }
    return location ? JSON.parse(location) : undefined;
}
function storeLocation(location) {
    window.localStorage.setItem(STORAGE_NAME, JSON.stringify(location));
}
function requestLocation() {
    return fetch('/api/location').then(response => response.json());
}
export function getLocation() {
    return readLocation();
}
export function detectLocation() {
    return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
        let location = readLocation();
        if (location && (location.manual || location.isFresh)) {
            resolve(location);
            return;
        }
        location = yield requestLocation();
        resolve(location);
    }));
}
export function setLocation(location, manual = false) {
    location = Object.assign(Object.assign({}, location), { manual, updated: new Date().toISOString(), isFresh: true });
    storeLocation(location);
    show(location);
    EventBus.emit('city:update', location);
}
export default function initLocation() {
    return __awaiter(this, void 0, void 0, function* () {
        let location = readLocation();
        if (!location) {
            location = yield requestLocation();
            setLocation(location);
        }
        else {
            show(location);
        }
    });
}
export function isMoscow(location) {
    return (location).fias === MOSCOW_FIAS;
}
export function isStPetersburg(location) {
    return location.fias === ST_PETERSBURG_FIAS;
}
