for (const button of document.querySelectorAll('.prod-qty__ctrl')) {
  button.addEventListener('click', function (event) {
    /** @type {HTMLInputElement} */
    const input = button.parentElement.querySelector('.prod-qty__input');
    event.preventDefault();

    if (!input) {
      return;
    }

    if (button.classList.contains('prod-qty__plus')) {
      try {
        input.stepUp();
      } catch {
        if (parseInt(input.max) && (parseInt(input.max) > input.value)) {
          input.value++;
        }
      }
    } else {
      try {
        input.stepDown();
      } catch {
        if (parseInt(input.value) > 1) {
          input.value--;
        }
      }
    }

    if (typeof input.onchange) {
      input.onchange();
    }
  })
}

for (const input of document.querySelectorAll('.prod-qty__input')) {
  input.onchange = () => {
    if (typeof updateQuantity !== 'function') {
      return;
    }

    updateQuantity(input.name, input.name.replace(/[^\d]+/, ''), input.step, false);
  }
}
