const easeInOutCubic = t => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
export default function scrollToElement(element, offset = 0) {
    const change = element.getBoundingClientRect().top + offset;
    if ('scrollBehavior' in document.documentElement.style && 'scrollBy' in window) {
        scrollBy({
            top: change,
            behavior: 'smooth'
        });
        return;
    }
    const duration = 1000;
    const start = window.scrollY;
    const to = start + change;
    const startDate = new Date().getTime();
    const animateScroll = () => {
        const currentDate = new Date().getTime();
        const currentTime = currentDate - startDate;
        window.scrollTo(window.scrollX, start + easeInOutCubic(currentTime / duration) * change);
        if (currentTime < duration) {
            window.requestAnimationFrame ? requestAnimationFrame(animateScroll) : animateScroll();
        }
        else {
            element.scrollTop = to;
        }
    };
    animateScroll();
}
